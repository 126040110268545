import React, { useState } from "react"
import { Link } from "gatsby"
import { GiHamburgerMenu } from "react-icons/gi"
import { SiYoutube, SiPatreon, SiBuymeacoffee, SiTwitter, SiFacebook, SiInstagram } from "react-icons/si";


export default function Nav() {

  const [isActive, setHidden] = useState(false)

  return (
    <nav className="sticky top-0 z-50 py-3 bg-black backdrop-filter backdrop-blur-md bg-opacity-90 shadow-lg text-gray-50 ">
      <div className=" px-10  mx-auto">
        <div className="flex justify-between">
          {/* logo */}
          <div className="flex items-center space-x-2">
            <Link to="/">
              <h1 className="md:text-2xl text-xl font-semibold pr-2 hover:text-rose-400 py-2">MAITAIS AT SUNSET</h1>
            </Link>


          </div>

          {/* primary nav */}
          <div className="hidden md:flex md:flex-row space-x-4 font-extralight  md:items-center">


            <Link to="/about" className="text-lg hover:text-rose-400">about</Link>

            <div className="h-[2.5rem] w-[0.1rem]  bg-white"></div>

            <div className="flex flex-row justify-center items-center space-x-4 text-2xl">
              <a href="https://twitter.com/maitaisatsunset" className="hover:text-rose-400" aria-label="Twitter"><SiTwitter /></a>
              <a href="https://www.facebook.com/maitaisatsunset/" className="hover:text-rose-400" aria-label="Facebook"><SiFacebook /></a>
              <a href="https://www.instagram.com/maitaisatsunset/" className="hover:text-rose-400" aria-label="Instagram"><SiInstagram /></a>
              <a href="https://www.youtube.com/channel/UCbhUinyTt6wpT48Vw7rxxZQ" className="hover:text-rose-400" aria-label="Youtube"><SiYoutube /></a>

            </div>

            <div className="h-[2.5rem] w-[0.1rem]  bg-white"></div>

            <div className="flex flex-row justify-center items-center space-x-4 text-2xl">
              <a href="https://www.buymeacoffee.com/MaiTaisAtSunset/" className="hover:text-rose-400" aria-label="Facebook"><SiBuymeacoffee /></a>
              <a href="https://www.patreon.com/Maitaisatsunset" className="hover:text-rose-400" aria-label="Twitter"><SiPatreon /></a>

            </div>

          </div>


          {/* hamburger icon */}
          <button className="flex items-center md:hidden">
            <GiHamburgerMenu onClick={() => setHidden(!isActive)} className={`text-3xl ${isActive ? "text-rose-400" : ""}`} />
          </button>
        </div>

      </div>
      {/* mobile menu */}
      <div id="mobileMenu" className={`md:hidden l px-4 min-h-screen ${isActive ? "flex" : "hidden"} justify-center items-center`}>
        <div className="flex flex-col font-extralight capitalize  space-y-4 text-3xl -mt-20">
          <Link to="/" className="hover:text-rose-400">Home</Link>
          <Link to="/about" className="hover:text-rose-400">About</Link>
          <div className="h-[0.1rem] w-full  bg-white"></div>
          <div className="flex flex-col items-center space-y-4  text-4xl">
            <a href="https://twitter.com/maitaisatsunset" className="hover:text-rose-400" aria-label="Twitter"><SiTwitter /></a>
            <a href="https://www.facebook.com/maitaisatsunset/" className="hover:text-rose-400" aria-label="Facebook"><SiFacebook /></a>
            <a href="https://www.instagram.com/maitaisatsunset/" className="hover:text-rose-400" aria-label="Instagram"><SiInstagram /></a>
            <a href="https://www.youtube.com/channel/UCbhUinyTt6wpT48Vw7rxxZQ" className="hover:text-rose-400" aria-label="Youtube"><SiYoutube /></a>

          </div>
          <div className="h-[0.1rem] w-full  bg-white"></div>
          <div className="flex flex-col items-center space-y-4  text-4xl">
            <a href="https://www.buymeacoffee.com/MaiTaisAtSunset/" className="hover:text-rose-400" aria-label="Facebook"><SiBuymeacoffee /></a>
            <a href="https://www.patreon.com/Maitaisatsunset" className="hover:text-rose-400" aria-label="Twitter"><SiPatreon /></a>

          </div>


        </div>
      </div>
    </nav>
  )
}