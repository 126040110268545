import React from "react"
import { Link } from "gatsby"
import { SiYoutube, SiPatreon, SiBuymeacoffee, SiTwitter, SiFacebook, SiInstagram } from "react-icons/si";

export default function Footer() {
  return (
    <div className="flex justify-center items-center bg-black">
      <div className="py-10 bg-black w-auto text-gray-50">
        <div className="px-6 max-w-3xl space-y-4 ">

          <div className="flex flex-row justify-center items-center space-x-4 text-2xl">
            <a href="https://twitter.com/maitaisatsunset" className="hover:text-rose-400" aria-label="Twitter"><SiTwitter /></a>
            <a href="https://www.facebook.com/maitaisatsunset/" className="hover:text-rose-400" aria-label="Facebook"><SiFacebook /></a>
            <a href="https://www.instagram.com/maitaisatsunset/" className="hover:text-rose-400" aria-label="Instagram"><SiInstagram /></a>
            <a href="https://www.youtube.com/channel/UCbhUinyTt6wpT48Vw7rxxZQ" className="hover:text-rose-400" aria-label="Youtube"><SiYoutube /></a>

          </div>
          <h1 className="text-4xl font-semibold text-center">MAITAIS AT SUNSET</h1>

          
          <div className="flex flex-row justify-center items-center space-x-4 capitalize font-thin">
            <Link to="/" className="hover:text-rose-400">Home</Link>
            <Link to="/about" className="hover:text-rose-400">About</Link>
          </div>
          <p className="text-sm font-extralight pt-10 text-gray-300">Maitais at Sunset is a brand of Meta Giraffe. All Rights Reserved 2021</p>


        </div>
      </div>
    </div>
  )

}